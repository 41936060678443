import React from "react"
import { Link } from 'gatsby'

export default () => {
    return (
        <div className="layout dark">
            <div className="layout__container">
                <div className="layout__wide copyright center">
                    <p>11 Montgomery Ave, Rothesay Bay, Auckland 0630 &nbsp; • &nbsp; <Link to='/terms/'>Terms</Link> &nbsp; • &nbsp; <Link to='/privacy/'>Privacy</Link></p>
                </div>
            </div>
        </div>
    )
}