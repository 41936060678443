import React from "react"
import { StaticQuery, graphql } from 'gatsby'
import Img from "gatsby-image"

export default () => (
    <StaticQuery
        query={graphql`
        {
            partner_1: file(relativePath: { eq: "MP_Logo.jpg" }) {
                childImageSharp {
                    fluid(maxWidth: 480) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
            partner_2: file(relativePath: { eq: "ACT_Safety.jpg" }) {
                childImageSharp {
                    fluid(maxWidth: 480) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
            partner_3: file(relativePath: { eq: "5_year_stamp.png" }) {
                childImageSharp {
                    fluid(maxWidth: 480) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
            partner_4: file(relativePath: { eq: "abbey_road_certificate.jpg" }) {
                childImageSharp {
                    fluid(maxWidth: 480) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
            partner_5: file(relativePath: { eq: "SiteSafe-logo.png" }) {
                childImageSharp {
                    fluid(maxWidth: 480) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
        }
    `} 
    render = { data => (
        <div className="layout body">
            <div className="layout__container">
                <div className="layout__wide">
                    <div className="grid grid__5 align__items center__items grid__gap--48">
                        <div>
                            <Img fluid={data.partner_1.childImageSharp.fluid} alt='Master Painters' />
                        </div>
                        <div>
                            <Img fluid={data.partner_2.childImageSharp.fluid} alt='ACT Safety' />
                        </div>
                        <div>
                            <Img fluid={data.partner_3.childImageSharp.fluid} alt='5 Year Painters Guarantee' />
                        </div>
                        <div>
                            <Img fluid={data.partner_4.childImageSharp.fluid} alt='Master Painters Membership' />
                        </div>
                        <div>
                            <Img fluid={data.partner_5.childImageSharp.fluid} alt='Site Safe NZ' />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )}
    />
)